import "@styles/globals.css";
import { appWithTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";
import Script from "next/script";
import { GoogleAnalytics } from "nextjs-google-analytics";

const App = ({ Component, pageProps }) => {
  return (
    <>
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="9f2dbf02-ff2a-4d2f-be40-9be033b7320b"
        data-blockingmode="auto"
        type="text/javascript"
      />
      <GoogleAnalytics trackPageViews />
      <DefaultSeo {...SEO} />
      <Component {...pageProps} />
    </>
  );
};

export default appWithTranslation(App);
