export default {
  titleTemplate: "Lptex | %s",
  defaultTitle: "Lptex, s.r.o.",
  canonical: "https://lptex.sk",
  description:
    "Sme stavebná spoločnosť pôsobiaca v rámci Slovenskej republiky, so zameraním na dopravené, železničné a vodohospodárske stavby. Poskytujeme služby v oblasti dopravy, nadrozmernej dopravy a prenájmu stavebnej techniky.",
  additionalMetaTags: [
    {
      property: "apple-mobile-web-app-title",
      content: "Lptex - Stavebná spoločnosť v Košiciach",
    },
    {
      property: "application-name",
      content: "Lptex - Stavebná spoločnosť v Košiciach",
    },
    {
      property: "msapplication-TileColor",
      content: "#ffffff",
    },
    {
      property: "theme-color",
      content: "#009FE3",
    },
  ],
  additionalLinkTags: [
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    {
      rel: "manifest",
      href: "/site.webmanifest",
    },
    {
      rel: "mask-icon",
      href: "/safari-pinned-tab.svg",
      color: "#009FE3",
    },
  ],
  languageAlternates: [
    {
      hrefLang: "en-US",
      href: "https://www.lptex.sk/en",
    },
    {
      hrefLang: "sk-SK",
      href: "https://www.lptex.sk/sk",
    },
  ],
  openGraph: {
    type: "website",
    locale: "sk_SK",
    url: "https://www.lptex.sk",
    site_name: "Lptex - Stavebná spoločnosť v košiciach",
    images: [
      {
        url: "/og-image.png",
        width: 1200,
        height: 628,
        alt: "Lptex - Stavebná spoločnosť v košiciach",
      },
    ],
  },
  twitter: {
    cardType: "summary_large_image",
  },
};
